var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-wrapper h-100",staticStyle:{"overflow":"hidden","overflow-y":"auto"}},[_c('div',{staticClass:"header-wrapper"},[_c('div',{staticClass:"header-content"},[_c('b-link',{staticClass:"brand-logo d-flex align-items-center",attrs:{"to":{ name: 'Login' }}},[_c('img',{attrs:{"src":this.appLogoImage,"width":"70","height":"70"}}),_c('h2',{staticClass:"brand-text text-primary m-0",staticStyle:{"font-size":"2rem","font-weight":"600","white-space":"nowrap"}},[_vm._v(" "+_vm._s(this.appName)+" ")])])],1)]),_c('div',{staticClass:"container-fluid",staticStyle:{"padding-top":"90px"}},[_c('div',{staticClass:"row mx-auto",staticStyle:{"max-width":"1500px"}},[_c('div',{staticClass:"col-md-6 p-0"},[_c('Guide')],1),_c('div',{staticClass:"col-md-6 p-5"},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Welcome to "),_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v(_vm._s(this.appName))])]),_c('validation-observer',{ref:"registerFormValidation"},[_c('b-form',{staticClass:"auth-register-form mt-1",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{staticClass:"mb-1"},[_c('validation-provider',{attrs:{"name":"Account Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"accountType"}},[_vm._v("Select Account Type")]),_c('div',{staticClass:"d-flex"},[_c('b-form-radio-group',{staticClass:"text-black",attrs:{"id":"is_officer"},model:{value:(_vm.is_officer),callback:function ($$v) {_vm.is_officer=$$v},expression:"is_officer"}},[_c('b-form-radio',{attrs:{"value":true}},[_c('span',[_vm._v("Officer ")]),_c('span',{staticClass:"ml-2",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","font-size":"12px !important","text-align":"right !important"}},[_vm._v("افسر")])]),_c('b-form-radio',{attrs:{"value":false}},[_c('span',[_vm._v("Family of Shaheed/Deceased ")]),_c('span',{staticClass:"ml-2",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","font-size":"12px !important","text-align":"right !important"}},[_vm._v("شہید/مرحوم کے لواحقین")])])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),(_vm.is_officer === true)?[_c('validation-provider',{attrs:{"name":"Officer Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"text-black",attrs:{"id":"userType"},model:{value:(_vm.userType),callback:function ($$v) {_vm.userType=$$v},expression:"userType"}},[_c('b-form-radio',{attrs:{"value":_vm.userTypes.SERVING}},[_c('span',[_vm._v("Serving Officer ")]),_c('span',{staticClass:"ml-2",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","font-size":"12px !important","text-align":"right !important"}},[_vm._v("حاضر سروس افسر")])]),_c('b-form-radio',{attrs:{"value":_vm.userTypes.RETIRED}},[_c('span',[_vm._v("Retired Officer ")]),_c('span',{staticClass:"ml-2",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","font-size":"12px !important","text-align":"right !important"}},[_vm._v("ریٹائرڈ افسر")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,962769389)})]:_vm._e()],2),_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"name"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("Name ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('span',{staticClass:"ml-2 d-inline-block",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","text-align":"right !important"}},[_vm._v("نام ")])])]),_c('validation-provider',{attrs:{"name":"name","rules":{ required: _vm.required, regex: /^[A-Za-z\s]{1,50}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('small',{staticClass:"text-danger",staticStyle:{"font-weight":"bold"}},[_vm._v(" Please do not enter your Rank along with your name ")]),_c('b-form-input',{class:{ 'red-background': _vm.rankError },attrs:{"id":"name","state":errors.length > 0 || _vm.rankError ? false : true,"name":"name","maxlength":"50","placeholder":"Name"},on:{"input":function($event){return _vm.checkForRank()}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),(errors.length)?_c('small',{staticClass:"text-danger",staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}])})],1),_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"cnic"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("CNIC ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('span',{staticClass:"ml-2 d-inline-block",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","text-align":"right !important"}},[_vm._v("شناختی کارڈ ")])])]),_c('validation-provider',{attrs:{"name":"CNIC","rules":"required|integer|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cnic","state":errors.length > 0 ? false : null,"name":"cnic","placeholder":"61101XXXXXXXX"},model:{value:(_vm.cnic),callback:function ($$v) {_vm.cnic=$$v},expression:"cnic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"mobile"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("Mobile ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('span',{staticClass:"ml-2 d-inline-block",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","text-align":"right !important"}},[_vm._v("موبائل")])])]),_c('validation-provider',{attrs:{"name":"Mobile","rules":"required|integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mobile","state":errors.length > 0 ? false : null,"name":"mobile","placeholder":"0310XXXXXXX"},model:{value:(_vm.mobile),callback:function ($$v) {_vm.mobile=$$v},expression:"mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"password"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("Password ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('span',{staticClass:"ml-2 d-inline-block",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","text-align":"right !important"}},[_vm._v("پاس ورڈ ")])])]),_c('validation-provider',{attrs:{"name":"Password","rules":"required|password|min:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"confirm-password"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("Confirm Password ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('span',{staticClass:"ml-2 d-inline-block",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","text-align":"right !important"}},[_vm._v("پاس ورڈ کی تصدیق کریں")])])]),_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"c-password","state":errors.length > 0 ? false : null,"type":_vm.passwordConFieldType,"name":"password","placeholder":"Re-type Password"},model:{value:(_vm.passwordCon),callback:function ($$v) {_vm.passwordCon=$$v},expression:"passwordCon"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordConToggleIcon},on:{"click":_vm.togglePasswordConVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.is_officer == false)?_c('span',[_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"cnic-shaheed"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("CNIC of Shaheed ")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('span',{staticClass:"ml-2 d-inline-block",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","text-align":"right !important"}},[_vm._v("شہید/مرحوم کا شناختی کارڈ نمبر ")])])]),_c('validation-provider',{attrs:{"name":"cnicshaheed","rules":"integer|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cnicshaheed","state":errors.length > 0 ? false : null,"name":"cnic","placeholder":"61101XXXXXXXX"},model:{value:(_vm.cnicShaheed),callback:function ($$v) {_vm.cnicShaheed=$$v},expression:"cnicShaheed"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,179251805)})],1),_c('b-form-group',[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":"relation-shaheed"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-inline-block"},[_c('span',[_vm._v("Your Relation with Shaheed/Deceased")]),_c('span',{staticClass:"text-danger"},[_vm._v(" *")])]),_c('span',{staticClass:"ml-2 d-inline-block",staticStyle:{"font-family":"'Noto Nastaliq Urdu', serif","text-align":"right !important"}},[_vm._v("شہید/مرحوم کے ساتھ آپ کا تعلق ")])])]),_c('validation-provider',{attrs:{"name":"Ward"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"vSelectStyle bg-white",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.relations,"placeholder":"Select relation with shaheed/deceased"},model:{value:(_vm.selectedRelationToShaheed),callback:function ($$v) {_vm.selectedRelationToShaheed=$$v},expression:"selectedRelationToShaheed"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,false,1485483836)})],1)],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""},on:{"click":_vm.validateForm}},[_vm._v(" Sign Up ")])],1)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{ name: 'Login' }}},[_c('span',[_vm._v(" Click here")])]),_c('p',{staticClass:"text-white border-danger rounded p-2 bg-danger mt-1"},[_c('feather-icon',{staticClass:"mr-1 font-weight-bolder",attrs:{"icon":"AlertTriangleIcon","size":"22"}}),_c('strong',[_vm._v("Portal services are currently available only for Officers of Pakistan Army")])],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }