<template>
  <div class="new-wrapper h-100" style="overflow: hidden; overflow-y: auto">
    <div class="header-wrapper">
      <div class="header-content">
        <b-link
          class="brand-logo d-flex align-items-center"
          :to="{ name: 'Login' }"
        >
          <img :src="this.appLogoImage" width="70" height="70" />
          <h2
            class="brand-text text-primary m-0"
            style="font-size: 2rem; font-weight: 600; white-space: nowrap"
          >
            {{ this.appName }}
          </h2>
        </b-link>
      </div>
    </div>

    <div class="container-fluid" style="padding-top: 90px">
      <div class="row mx-auto" style="max-width: 1500px">
        <div class="col-md-6 p-0">
          <Guide />
        </div>

        <div class="col-md-6 p-5">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Welcome to
            <span class="text-primary font-weight-bold">{{
              this.appName
            }}</span>
          </b-card-title>

          <validation-observer ref="registerFormValidation">
            <b-form class="auth-register-form mt-1" @submit.prevent>
            
              <b-form-group class="mb-1">
              <validation-provider
              #default="{ errors }"
              name="Account Type"
              rules="required">
                <label for="accountType">Select Account Type</label>

                <div class="d-flex">
                  <b-form-radio-group
                    id="is_officer"
                    v-model="is_officer"
                    class="text-black"
                  >
                    <b-form-radio :value="true">
                      <span>Officer </span>
                      <span
                        class="ml-2"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          font-size: 12px !important;
                          text-align: right !important;
                        "
                        >افسر</span
                      >
                    </b-form-radio>
                    <b-form-radio :value="false">
                      <span>Family of Shaheed/Deceased </span>
                      <span
                        class="ml-2"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          font-size: 12px !important;
                          text-align: right !important;
                        "
                        >شہید/مرحوم کے لواحقین</span
                      >
                    </b-form-radio>
                  </b-form-radio-group>
                </div>
             <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
                <template v-if="is_officer === true">
                 <validation-provider
                    #default="{ errors }"
                    name="Officer Type"
                    rules="required">
                  <b-form-radio-group
                    id="userType"
                    v-model="userType"
                    class="text-black"
                  >
                    <b-form-radio :value="userTypes.SERVING">
                      <span>Serving Officer </span>
                      <span
                        class="ml-2"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          font-size: 12px !important;
                          text-align: right !important;
                        "
                        >حاضر سروس افسر</span
                      >
                    </b-form-radio>
                    <b-form-radio :value="userTypes.RETIRED">
                      <span>Retired Officer </span>
                      <span
                        class="ml-2"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          font-size: 12px !important;
                          text-align: right !important;
                        "
                        >ریٹائرڈ افسر</span
                      >
                    </b-form-radio>
                  </b-form-radio-group>
               <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
                </template>
              </b-form-group>

              <b-form-group>
                <label for="name" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Name </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >نام
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="name"
                  :rules="{ required, regex: /^[A-Za-z\s]{1,50}$/ }"
                >
                   <small class="text-danger"
                      style="font-weight: bold;"
                  >
                      Please do not enter your Rank along with your name
                  </small>
                  <b-form-input
                    id="name"
                    v-model="name"
                    :class="{ 'red-background': rankError }"
                    :state="errors.length > 0 || rankError ? false : true"
                    name="name"
                    maxlength="50"
                    placeholder="Name"
                    @input="checkForRank()"
                  />
                  <small v-if="errors.length" class="text-danger" style="font-weight: bold;">{{
                    errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="cnic" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>CNIC </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >شناختی کارڈ
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="CNIC"
                  rules="required|integer|length:13"
                >
                  <b-form-input
                    id="cnic"
                    v-model="cnic"
                    :state="errors.length > 0 ? false : null"
                    name="cnic"
                    placeholder="61101XXXXXXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="mobile" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Mobile </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >موبائل</span
                    >
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Mobile"
                  rules="required|integer|length:11"
                >
                  <b-form-input
                    id="mobile"
                    v-model="mobile"
                    :state="errors.length > 0 ? false : null"
                    name="mobile"
                    placeholder="0310XXXXXXX"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="password" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Password </span><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >پاس ورڈ
                    </span>
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|password|min:12"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <label for="confirm-password" style="width: 100%">
                  <div class="d-flex justify-content-between">
                    <div class="d-inline-block">
                      <span>Confirm Password </span
                      ><span class="text-danger"> *</span>
                    </div>
                    <span
                      class="ml-2 d-inline-block"
                      style="
                        font-family: 'Noto Nastaliq Urdu', serif;
                        text-align: right !important;
                      "
                      >پاس ورڈ کی تصدیق کریں</span
                    >
                  </div>
                </label>

                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="c-password"
                      v-model="passwordCon"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordConFieldType"
                      name="password"
                      placeholder="Re-type Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordConToggleIcon"
                        @click="togglePasswordConVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <span v-if="is_officer == false">
                <b-form-group>
                  <label for="cnic-shaheed" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span>CNIC of Shaheed </span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                        "
                        >شہید/مرحوم کا شناختی کارڈ نمبر
                      </span>
                    </div>
                  </label>

                  <validation-provider
                    #default="{ errors }"
                    name="cnicshaheed"
                    rules="integer|length:13"
                  >
                    <b-form-input
                      id="cnicshaheed"
                      v-model="cnicShaheed"
                      :state="errors.length > 0 ? false : null"
                      name="cnic"
                      placeholder="61101XXXXXXXX"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <label for="relation-shaheed" style="width: 100%">
                    <div class="d-flex justify-content-between">
                      <div class="d-inline-block">
                        <span>Your Relation with Shaheed/Deceased</span
                        ><span class="text-danger"> *</span>
                      </div>
                      <span
                        class="ml-2 d-inline-block"
                        style="
                          font-family: 'Noto Nastaliq Urdu', serif;
                          text-align: right !important;
                        "
                        >شہید/مرحوم کے ساتھ آپ کا تعلق
                      </span>
                    </div>
                  </label>

                  <validation-provider #default="{ errors }" name="Ward">
                    <v-select
                      v-model="selectedRelationToShaheed"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="relations"
                      class="vSelectStyle bg-white"
                      placeholder="Select relation with shaheed/deceased"
                    >
                    </v-select>
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </span>

              <b-button
                type="submit"
                variant="primary"
                block
                @click="validateForm"
              >
                Sign Up
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <span>Already have an account? </span>
            <b-link :to="{ name: 'Login' }">
              <span>&nbsp;Click here</span>
            </b-link>
              <p class="text-white border-danger rounded p-2 bg-danger mt-1"><feather-icon icon="AlertTriangleIcon" size="22" class="mr-1 font-weight-bolder"/><strong>Portal services are currently available only for Officers of Pakistan Army</strong></p>
          </b-card-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { $themeConfig } from "@themeConfig";
import { mapActions } from "vuex";
import util from "@/util.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Guide from "@/components/newHome/Guide.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePicker,
    Guide,
  },
  mixins: [util],
  data() {
    return {
      show: false,
      appName: "",
      appLogoImage: "",
      parent_cnic: null,
      parent_name: null,
      is_officer: null,
      password: "",
      passwordCon: "",
      cnic: "",
      dateOfRetirement: "",
      mobile: "",
      role: "nu",
      userType: '',
      email: null,
      name: null,
      motherName: null,
      dateOfBirth: null,
      cnicShaheed: null,
      nameShaheed: null,
      relations: [
        "Father",
        "Mother",
        "Husband",
        "Wife",
        "Son",
        "Daughter",
        "Brother",
        "Sister",
      ],
      selectedRelationToShaheed: null,
      passwordFieldType: "password",
      passwordConFieldType: "password",
      required,
      ranks: [
        "Gen",
        "General",
        "Maj Gen",
        "Major Gen",
        "Major General",
        "Lt General",
        "Brig",
        "Brigadier",
        "Col",
        "Colonel",
        "Lt Col",
        "Lt Colonel",
        "Maj",
        "Major",
        "Capt",
        "Captain",
        "Lt",
        "Lieutenant",
        "2/Lt",
        "2/Lieutenant",
        "Hon Capt",
        "Hon Captain",
        "Hon Lt",
        "Majo",
        "Captan",
      ],
      rankError: "", 
   };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ register: "appData/register" }),
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    async checkForRank() {
      if (!this.name) {
        this.$refs.registerFormValidation.reset();
                this.rankError = false;
        return true;
      }
      const regex = new RegExp("\\b(" + this.ranks.join("|") + ")\\b", "i");
      if (regex.test(this.name)) {
        this.$refs.registerFormValidation.setErrors({
          name: ["You cannot enter a rank along with the name"],
        }); 
        this.rankError = true;
        return false;
      } else if (!/^[A-Za-z\s]{1,50}$/.test(this.name)) {
        this.$refs.registerFormValidation.setErrors({
          name: ["Name must contain only letters and spaces"],
        });
  
        this.rankError = true;
        return false;
      } else {
        this.$refs.registerFormValidation.reset();
        this.rankError = false;
        return true;
      }
    },

    disabledRangeForDOB: function (date) {
      var today = new Date();
      var newDate = new Date(date);
      return newDate > today;
    },
    togglePasswordConVisibility() {
      this.passwordConFieldType =
        this.passwordConFieldType === "password" ? "text" : "password";
    },
    async validateForm() {
      const isValid = await this.checkForRank();
      if (!isValid) {
        console.log("Validation failed, form not submitted.");
        return;
      }
      const success = await this.$refs.registerFormValidation.validate();
      if (success) {
        await this.submit();
      }
    },
    async submit() {
      this.show = true;
      try {

        if (this.is_officer == null ) {
          this.$refs.registerFormValidation.setErrors({
            abc: ["Please select any Account Type "],
          });
        }

        if (!this.is_officer && this.cnic === this.cnicShaheed) {
          this.$swal({
            title: "CNIC of shaheed and user are the same",
            icon: "error",
            timer: 3000,
          });
          this.cnicShaheed = null;
          this.show = false;
          return;
        }

        if (this.is_officer == false) {
          this.userType = this.userTypes.CIVILIAN;
        }

        const data = {
          cnic: this.cnic,
          mobile: this.mobile,
          role: this.role,
          password: this.password,
          name: this.name,
          is_officer: this.is_officer,
          relation_with_shaheed: this.selectedRelationToShaheed,
          cnic_shaheed: this.cnicShaheed,
          user_type: this.userType,
        };
        const res = await this.register(data);
        this.show = false;

        if (res.status === 201) {
          this.$swal({
            title:
              "Your Credentials have been sent for verification. You will Receive a Call From 051 111130786 for Data Verification, Please verify Your Particulars when you Receive Call from this Number. You will be intimated via SMS on approval of Registration.",
            icon: "success",
          });
          this.$router.push({ name: "Login" });
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    passwordConToggleIcon() {
      return this.passwordConFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.new-wrapper {
  background: url("~@/assets/images/slider/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 2;
}
.new-wrapper::-webkit-scrollbar {
  display: none;
}
.brand-logo {
  z-index: 10;
  position: fixed;
  top: 0;
}
.header-wrapper {
  width: 100%;
  background: url("~@/assets/images/slider/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: fixed;
  top: 0;
  z-index: 100;
  height: 80px;
}

.header-content {
  max-width: 1000px;
}

.red-background {
  background-color: rgb(211, 177, 177);
}

</style>